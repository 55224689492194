export const ACTIONS = {
  RECEIVE: 'receive',
  DISTRIBUTE: 'distribute',
  DISTRIBUTE_REDISPATCH: 'distribute_redispatch',
  SORT: 'sort',
  PACKING: 'packing',
  IDENTIFY: 'identify',
  DISPATCH: 'dispatch',
  PREPARE: 'prepare',
  TRANSPORT: 'transport',
  CHANGE_LABEL: 'change_label',
  UNIT_LOAD: 'unit_load',
  DISTRIBUTE_TRANSFERS: 'distribute_transfers',
  DISTRIBUTE_PARTNERS: 'distribute_partners',
  ORGANIZE: 'organize',
  PRINTER: 'printer'
};

export const rules = {
  LEVE: [
    ACTIONS.RECEIVE,
    ACTIONS.DISTRIBUTE_TRANSFERS,
    ACTIONS.ORGANIZE,
    ACTIONS.PACKING,
    ACTIONS.TRANSPORT,
    ACTIONS.IDENTIFY
  ],
  AGENCY: [
    ACTIONS.RECEIVE,
    ACTIONS.DISTRIBUTE,
    ACTIONS.DISTRIBUTE_TRANSFERS,
    ACTIONS.DISTRIBUTE_REDISPATCH,
    ACTIONS.SORT,
    ACTIONS.PACKING,
    ACTIONS.IDENTIFY,
    ACTIONS.DISPATCH,
    ACTIONS.PREPARE,
    ACTIONS.TRANSPORT,
    ACTIONS.CHANGE_LABEL,
    ACTIONS.UNIT_LOAD,
    ACTIONS.ORGANIZE,
    ACTIONS.PRINTER,
    ACTIONS.DISTRIBUTE_PARTNERS
  ]
};
