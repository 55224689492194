import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Divider,
  List,
  ListItem,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {
  ACTIVITY,
  HISTORY_ACTIONS,
  OPERATIONAL_PROCESS,
  ROUTES,
  UNIFIED_RECEIVE_UNIT_LOAD_SUFFIX,
  COGNITO_DISTRIBUTION_CENTER,
  SWITCHES
} from '../../../constants';
import { ReceivingProcessContext } from '../receiving-process-context';
import showSnackbar from '../../../app/components/snackbar/snackbar-container';
import { playErrorBeep, playSuccessBeep } from '../../../sounds';
import { identifyPackagesToReceive, movePackage } from '../../../api-rest';
import handleRestAPIError from '../../../app/utils/rest-api-request';
import { PackageReader } from '../../../app/components/barcode-readers';
import { useDistributionCenter } from '../../../app/access-control/distribution-center-provider';
import { ActivityTrackingContext } from '../../../app/activity-tracking/activity-tracking-provider';
import pluralize from '../../../app/utils/pluralize';
// eslint-disable-next-line import/no-cycle
import { resolvePackageStatusMessage } from '../receive';
import SortingRecommendation from '../sorting-recommendation';
import DecorativeHeader from '../../../app/components/decorative-header';
import getUserType from '../../../app/access-control/access-control-service';
// eslint-disable-next-line import/no-cycle
import NewReceivePackage from '../new-receive';
import { useFeature } from '../../../app/hooks/use-feature';
import { PACKAGE_ORIGIN } from '../../../app/enums';
// eslint-disable-next-line import/no-cycle
import ReceivePartner from '../partner/receive-partner';

export const MESSAGES = {
  DUPLICATED_BEEP: 'Opa, unidade já bipada!',
  RECEIVE_SUCCESS: 'Pronto, tudo certo.\nUnidades recebidas.',
  NOT_FOUND: 'Código não encontrado'
};

export const BUTTONS = {
  CONFIRM: 'Pronto, recebidos'
};

const ReceiveCounter = () => {
  const {
    packageBarcode,
    sortingContext,
    returnList,
    setReturnList,
    packToReceive,
    receivedSenderPackages,
    setReceivedSenderPackages,
    senderPackages,
    sender,
    setSender,
    setShouldShowSender,
    setPackageBarcode,
    setErrorMessage,
    setPackToReceive,
    receiveSortingContext,
    packagesOrigin
  } = useContext(ReceivingProcessContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const siblingSorterLicensePlate =
    receiveSortingContext &&
    'siblingsSorterLicensePlate' in receiveSortingContext
      ? receiveSortingContext?.siblingsSorterLicensePlate[0]
      : null;

  const defaultReceivingUnitLoad = `${
    selectedDistributionCenter.routingCode
  } ${UNIFIED_RECEIVE_UNIT_LOAD_SUFFIX}`;

  const enableNewReceiveFlowWithSibling = useFeature(
    SWITCHES.enableNewReceiveFlowWithSibling
  );

  const enableNewReceiveFlow = useFeature(SWITCHES.enableNewReceiveFlow);

  const enableFilterByTrackingKey = useFeature(
    SWITCHES.enableFilterByTrackingKey
  );

  const enablePackageOriginPartnerFlow = useFeature(
    SWITCHES.enablePackageOriginPartnerFlow
  );

  const isPackageOriginPartner =
    packagesOrigin === PACKAGE_ORIGIN.PACKAGE_ORIGIN_PARTNER &&
    enablePackageOriginPartnerFlow;

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const baseErrorHandler = message => {
    playErrorBeep();
    showSnackbarAlert(message, 'error');
    setLoading(false);
  };

  const isFromSameOrigin = barcode => {
    return senderPackages.filter(pack => pack.barcode === barcode).length > 0;
  };

  const addReturn = ({
    barcode,
    deliveryStatus,
    unitLoadLP,
    receivedUnitLoadId
  }) => {
    const isSameOrigin = isFromSameOrigin(barcode);
    if (isSameOrigin) setReceivedSenderPackages(receivedSenderPackages + 1);

    setReturnList([
      {
        package: barcode,
        deliveryStatus,
        unitLoad: unitLoadLP,
        isSameOrigin: true,
        unitLoadId: receivedUnitLoadId
      },
      ...returnList
    ]);
  };

  const movePackageToDefaultUL = barcode => {
    return movePackage(
      defaultReceivingUnitLoad,
      barcode,
      defaultReceivingUnitLoad,
      sortingContext,
      {
        distributionCenterId: selectedDistributionCenter?.distributionCenterId
      }
    );
  };

  const processCounterPackage = async (
    barcode,
    receivedUnitLoadId,
    goBackOnCancel = false
  ) => {
    try {
      await movePackageToDefaultUL(barcode);
      const packageStatusMessage = resolvePackageStatusMessage(packToReceive);
      addReturn({
        barcode,
        deliveryStatus: packageStatusMessage,
        unitLoadLP: defaultReceivingUnitLoad,
        receivedUnitLoadId
      });
      playSuccessBeep();
    } catch (err) {
      handleRestAPIError(err, baseErrorHandler);
      if (goBackOnCancel) history.goBack();
    }
  };

  const handleFirstPackage = async (barcode, receivedUnitLoadId) => {
    setLoading(true);
    await processCounterPackage(barcode, receivedUnitLoadId, true);
    setLoading(false);
  };

  const { trackEnd, trackStart } = useContext(ActivityTrackingContext);

  const isUserLeve = () => {
    return (
      getUserType(selectedDistributionCenter) ===
      COGNITO_DISTRIBUTION_CENTER.LEVE_USER
    );
  };

  const finish = pathname => {
    history.push({
      pathname
    });
  };

  const receiveSuccess = () => {
    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_PACKAGE);
    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.RECEIVE_COUNTER);
    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.FULL_PROCESS);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);

    if (isUserLeve()) {
      showSnackbarAlert(MESSAGES.RECEIVE_SUCCESS);
      playSuccessBeep();
      finish(ROUTES.RECEIVE.CONTEXT_READER);
    } else if (enableNewReceiveFlowWithSibling && siblingSorterLicensePlate) {
      history.replace({
        pathname: ROUTES.RECEIVE.CONFIRMATION,
        state: {
          totalPackagesReceived: returnList.length,
          senderName: sender?.name || '',
          receivedIn: moment(new Date()).format('DD MMM[,] HH:mm')
        }
      });
    } else {
      finish(ROUTES.RECEIVE.RECEIVED_PACKAGES);
    }
  };

  const handleChange = () => {
    setErrorMessage('');
    setPackageBarcode('');
  };

  const isDuplicatedPackageBeep = barcode =>
    returnList.some(item => item.package === barcode);

  const handlePackageToReceive = async responsePackage => {
    setPackToReceive(responsePackage);
    setPackageBarcode(responsePackage.barcode);

    if (isDuplicatedPackageBeep(responsePackage.barcode)) {
      showSnackbarAlert(MESSAGES.DUPLICATED_BEEP, 'warning');
      return;
    }

    await processCounterPackage(
      responsePackage.barcode,
      responsePackage.packageId
    );
  };

  const handleRead = async barcode => {
    setLoading(true);

    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.RECEIVE_COUNTER_BEEP);

    const response = await identifyPackagesToReceive(barcode).catch(err => {
      handleRestAPIError(err, errorMsg => setErrorMessage(errorMsg));
      playErrorBeep();
      setLoading(false);
    });
    if (!response) {
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.RECEIVE_COUNTER_BEEP);
      return;
    }

    const missingPackages = response?.data?.missingPackages;

    if (missingPackages) {
      let receivedPackage = missingPackages.filter(
        pack => pack.barcode === barcode
      )[0];
      if (!receivedPackage && enableFilterByTrackingKey) {
        // eslint-disable-next-line prefer-destructuring
        receivedPackage = missingPackages.filter(
          pack => pack.trackingKey === barcode
        )[0];
      }

      if (!receivedPackage) {
        setLoading(false);
        showSnackbarAlert(MESSAGES.NOT_FOUND, 'warning');
        return;
      }

      await handlePackageToReceive(receivedPackage);
    }

    setLoading(false);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.RECEIVE_COUNTER_BEEP);
    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_PACKAGE);
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_PACKAGE);
  };

  useEffect(
    () => {
      // prevent user for opening this page when editing the URL
      if (
        history.action !== HISTORY_ACTIONS.PUSH ||
        !sortingContext ||
        !packageBarcode ||
        !packToReceive?.packageId
      ) {
        history.replace(ROUTES.RECEIVE.CONTEXT_READER);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );

  /**
   * For the first package only
   * we execute this effect
   */
  useEffect(() => {
    (async function processPackage() {
      if (packageBarcode && packToReceive?.packageId)
        await handleFirstPackage(packageBarcode, packToReceive.packageId);

      if (isPackageOriginPartner) {
        setShouldShowSender(true);
        setSender(sender);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return enableNewReceiveFlow ? (
    <NewReceivePackage
      goBack={() => history.push('/')}
      sortingContext={sortingContext}
      totalPackages={senderPackages.length}
      receivedPackages={returnList}
      sender={sender}
      completeReceive={() => receiveSuccess()}
    >
      <Box className="centered" p={1} mt={2.5}>
        <PackageReader
          onRead={handleRead}
          onChange={handleChange}
          loading={loading}
          notes="Bipe na tela de recebimento balcão"
          placeholder="Bipe o lacre ou etiqueta"
        />
      </Box>
    </NewReceivePackage>
  ) : (
    <PackageBeepScreen
      goBack={() => history.push('/')}
      showSnackbarAlert={showSnackbarAlert}
      processCounterPackage={processCounterPackage}
      loading={loading}
      setLoading={setLoading}
      selectedDistributionCenter={selectedDistributionCenter}
      receiveSuccess={receiveSuccess}
      handleChange={handleChange}
      handleRead={handleRead}
    />
  );
};

const PackageBeepScreen = ({
  loading,
  receiveSuccess,
  handleRead,
  handleChange,
  goBack
}) => {
  const { returnList, errorMessage, packagesOrigin } = useContext(
    ReceivingProcessContext
  );
  const { trackStart } = useContext(ActivityTrackingContext);

  const enablePackageOriginPartnerFlow = useFeature(
    SWITCHES.enablePackageOriginPartnerFlow
  );

  const isPackageOriginPartner =
    packagesOrigin === PACKAGE_ORIGIN.PACKAGE_ORIGIN_PARTNER &&
    enablePackageOriginPartnerFlow;

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.RECEIVE_COUNTER);
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_PACKAGE);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isPackageOriginPartner ? (
    <ReceivePartner
      receivedPackages={returnList}
      goBack={goBack}
      completeReceive={receiveSuccess}
    >
      <Box className="centered" mt={2.5}>
        <PackageReader
          onRead={handleRead}
          onChange={handleChange}
          loading={loading}
          notes="Bipe na tela de recebimento balcão"
          placeholder="Bipe o lacre ou etiqueta"
        />
      </Box>
    </ReceivePartner>
  ) : (
    <Box height="100vh" clone>
      <Container maxWidth="xs">
        <Box
          pt={3}
          display="flex"
          flexDirection="column"
          height={1}
          overflow="hidden"
        >
          <Box>
            <DecorativeHeader testId="receive-counter-cancel-button" />
            <Box paddingTop={1.5}>
              <Typography component="div" variant="body1" gutterBottom>
                <Box fontWeight="fontWeightBold">Receber</Box>
              </Typography>
            </Box>

            <Box display="flex">
              <Typography
                color="secondary"
                variant="h3"
                data-testid="checked-unitloads-count"
              >
                {returnList.length}
              </Typography>
            </Box>

            <Typography variant="body1">
              {`${pluralize({
                singular: 'unidade',
                count: returnList.length
              })}`}
            </Typography>

            <Box className="centered" mt={2.5}>
              <PackageReader
                onRead={handleRead}
                onChange={handleChange}
                loading={loading}
                notes="Bipe na tela de recebimento balcão"
                placeholder="Bipe o lacre ou etiqueta"
              />
            </Box>
            {errorMessage && (
              <Box mt={1.5}>
                <Alert severity="error">{errorMessage}</Alert>
              </Box>
            )}
          </Box>

          <Box my={2} flex={1} style={{ overflowY: 'auto' }}>
            <PackageList receivedPackages={returnList} />
          </Box>
          <Box mb={1.5} flex="initial">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              data-testid="receive-counter-finish"
              onClick={receiveSuccess}
              disabled={loading}
            >
              {BUTTONS.CONFIRM}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

PackageBeepScreen.propTypes = {
  loading: PropTypes.bool.isRequired,
  receiveSuccess: PropTypes.func.isRequired,
  handleRead: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
};

const PackageList = ({ receivedPackages }) => {
  const { sortingContext } = useContext(ReceivingProcessContext);

  return (
    <List data-testid="counter-package-list">
      <Divider />
      {receivedPackages.map(receivedPackage => (
        <PackageItem
          key={`${receivedPackage.package}-${receivedPackage.unitLoad}`}
          receivedPackage={receivedPackage}
          sortingContext={sortingContext}
        />
      ))}
    </List>
  );
};

PackageList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  receivedPackages: PropTypes.array.isRequired
};

const PackageItem = ({ receivedPackage, sortingContext }) => {
  return (
    <ListItem divider>
      <Box my={1} paddingLeft={0}>
        {receivedPackage.deliveryStatus && (
          <Typography variant="body2">
            {receivedPackage.deliveryStatus}
          </Typography>
        )}
        <Typography variant="body2">#{receivedPackage.package}</Typography>
        <SortingRecommendation
          sortingContext={sortingContext}
          barcode={receivedPackage.package}
        />
      </Box>
    </ListItem>
  );
};

PackageItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  receivedPackage: PropTypes.object.isRequired,
  sortingContext: PropTypes.string.isRequired
};

export default ReceiveCounter;
