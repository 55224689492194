import React, { useContext, useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { PDFDocument } from 'pdf-lib';
import * as Sentry from '@sentry/browser';
import { pxToRem } from '@loggi/mar/src/utils';
import Avatar from '@material-ui/core/Avatar';
import HeaderWithReturn from '../../app/components/header-with-return';
import {
  playActionRequiredBeep,
  playErrorBeep,
  playSuccessBeep
} from '../../sounds';
import PrepareInfoReader from './prepare-info-reader';
import PrintingPackages from './printing-packages';
import {
  ACTIVITY,
  HISTORY_ACTIONS,
  OPERATIONAL_PROCESS,
  ROUTES
} from '../../constants';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { packageCheck } from '../../api';
import { GeolocationContext } from '../../geo';
import useDeviceDetect from '../../app/hooks/use-device-detect';
import PackagesWithoutLabelDialog from './packages-without-label-dialog';
import Confirmation from '../../app/components/confirmation';
import AlertIcon from '../../assets/images/alert-icon-2.svg';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';

export const MESSAGES = {
  PREPARE_ERROR: 'Erro ao preparar pacotes',
  PREPARE_SUCCESS: 'Boa! Tudo certo no preparo dos pacotes.',
  LABEL_ALREADY_BEEPED: 'Opa, código já bipado!',
  INVALID_LABELS: 'Etiquetas inválidas identificadas',
  FAILED_MERGE: 'Não foi possível juntar as etiquetas',
  FAILED_PRINT: 'Não foi possível imprimir',
  IMPOSSIBLE_TO_PRINT_LABEL:
    'Eita, não é possível preparar esse(s) pacote(s). Fale com o suporte.'
};

export default function Prepare() {
  const history = useHistory();
  const location = useLocation();
  const { isMobile } = useDeviceDetect();
  const { enqueueSnackbar } = useSnackbar();
  const [geo] = React.useContext(GeolocationContext);

  const [pkgsToPrepareInfo, setPkgsToPrepareInfo] = useState(
    location.state?.pkgsToPrepareInfo || []
  );
  const [
    pkgsToPrepareInfoWithoutLabel,
    setPkgsToPrepareInfoWithoutLabel
  ] = useState([]);
  const [mainLabelType] = useState(location.state?.mainLabelType);
  const [distributionCenterId] = useState(location.state?.distributionCenterId);
  const [postingCard] = useState(location.state?.postingCard || {});
  const [openConfirmGoBack, setOpenConfirmGoBack] = React.useState(false);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const [printReason] = useState(location.state?.printReason);

  const goBack = () => {
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    trackEnd(OPERATIONAL_PROCESS.PREPARE, ACTIVITY.FULL_PROCESS);
    history.push({ pathname: ROUTES.PREPARE.CHOOSE_LABEL_TYPE });
  };

  const handleCancelGoBack = () => {
    setOpenConfirmGoBack(false);
  };

  const handleGoBack = () => {
    if (pkgsToPrepareInfo.length) {
      setOpenConfirmGoBack(true);
      return;
    }

    goBack();
  };

  const finish = (pkgsWithoutLabel = []) => {
    setPkgsToPrepareInfoWithoutLabel(pkgsWithoutLabel);
    setPkgsToPrepareInfo([]);
  };

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const handleError = message => {
    showSnackbarAlert(message, 'error');
    playErrorBeep();

    trackEnd(OPERATIONAL_PROCESS.PREPARE, ACTIVITY.BEEP_PACKAGE);
    trackStart(OPERATIONAL_PROCESS.PREPARE, ACTIVITY.BEEP_PACKAGE);
  };

  const isDuplicatedBeep = packageToPrintInfo =>
    pkgsToPrepareInfo.filter(
      pkgInfo => pkgInfo.pkg.identifier === packageToPrintInfo.pkg.identifier
    )[0];

  const handlePrepareInfo = packageToPrintInfo => {
    trackEnd(OPERATIONAL_PROCESS.PREPARE, ACTIVITY.BEEP_PACKAGE);
    trackStart(OPERATIONAL_PROCESS.PREPARE, ACTIVITY.BEEP_PACKAGE);
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.PREPARE_PACKAGE_BEEP);

    if (isDuplicatedBeep(packageToPrintInfo)) {
      showSnackbarAlert(MESSAGES.LABEL_ALREADY_BEEPED, 'warning');
      playActionRequiredBeep();
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.PREPARE_PACKAGE_BEEP);
      return;
    }

    playSuccessBeep();
    setPkgsToPrepareInfo([packageToPrintInfo, ...pkgsToPrepareInfo]);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.PREPARE_PACKAGE_BEEP);
  };

  const handleRemovePackages = pkgIdentifiersToRemove => {
    setPkgsToPrepareInfo(
      pkgsToPrepareInfo.filter(
        pkgInfo => !pkgIdentifiersToRemove.includes(pkgInfo.pkg.identifier)
      )
    );
  };

  const blobPdfFromBaseUint8Array = baseUint8Array => {
    try {
      return new Blob([baseUint8Array], { type: 'application/pdf' });
    } catch (err) {
      Sentry.captureException(err);
      throw new Error(MESSAGES.INVALID_LABELS);
    }
  };

  const mergePdfs = async base64Pdfs => {
    try {
      const mergedPdf = await PDFDocument.create();

      const pdfReadPromises = base64Pdfs.map(item => PDFDocument.load(item));
      const pdfs = await Promise.all(pdfReadPromises);

      const contentPagesPromises = pdfs.map(pdf =>
        mergedPdf.copyPages(pdf, pdf.getPageIndices())
      );
      const contentPages = await Promise.all(contentPagesPromises);

      contentPages.forEach(contentPage =>
        contentPage.forEach(page => mergedPdf.addPage(page))
      );

      return mergedPdf.save();
    } catch (err) {
      Sentry.captureException(err);
      throw new Error(MESSAGES.FAILED_MERGE);
    }
  };

  const downloadBlobDesktop = blobUrl => {
    try {
      const frameId = 'printing';

      // To prevent duplication and issues, remove any used printFrame from the DOM
      const usedFrame = document.getElementById(frameId);
      if (usedFrame) usedFrame.parentNode.removeChild(usedFrame);

      const printFrame = document.createElement('iframe');
      printFrame.setAttribute('id', frameId);
      printFrame.setAttribute(
        'style',
        'visibility: hidden; height: 0; width: 0; position: absolute; border: 0'
      );

      printFrame.setAttribute('src', blobUrl);

      document.getElementsByTagName('body')[0].appendChild(printFrame);
      printFrame.focus();
      printFrame.contentWindow.print();
    } catch (err) {
      Sentry.captureException(err);
      throw new Error(MESSAGES.FAILED_PRINT);
    }
  };

  const downloadBlobMobile = blobUrl => {
    window.open(blobUrl, '_blank');
  };

  const downloadBlob = blob => {
    const blobUrl = URL.createObjectURL(blob);

    if (isMobile) {
      downloadBlobMobile(blobUrl);
      return;
    }

    downloadBlobDesktop(blobUrl);
  };

  const checkPackages = () => {
    const source = 'xd_app';

    const packagesCheckPromises = pkgsToPrepareInfo.map(pkgInfo =>
      packageCheck(
        geo,
        pkgInfo.mainLabel.packageCheckIdentifier,
        pkgInfo.mainLabel.packageCheckNotes,
        source
      )
    );
    return Promise.all(packagesCheckPromises);
  };

  const getPrintInfo = () => {
    const base64Pdfs = [];
    const pkgsWithoutLabel = [];

    // We have to reverse the order of the label printing,
    // to match the order of the beeping
    for (let index = pkgsToPrepareInfo.length - 1; index >= 0; index -= 1) {
      const pkgInfo = pkgsToPrepareInfo[index];
      if (pkgInfo.mainLabel.base64Pdf) {
        base64Pdfs.push(pkgInfo.mainLabel.base64Pdf);
      } else {
        pkgsWithoutLabel.push(pkgInfo);
      }
    }

    return {
      base64Pdfs,
      pkgsWithoutLabel
    };
  };

  const handleConfirmPrint = async () => {
    const { base64Pdfs, pkgsWithoutLabel } = getPrintInfo();

    if (!base64Pdfs.length) {
      handleError(MESSAGES.IMPOSSIBLE_TO_PRINT_LABEL);
      return;
    }

    try {
      const mergedPdf = await mergePdfs(base64Pdfs);

      const blob = blobPdfFromBaseUint8Array(mergedPdf);
      downloadBlob(blob);
    } catch (err) {
      handleError(err.message);
      return;
    }

    checkPackages().catch(Sentry.captureException);

    if (!pkgsWithoutLabel.length) {
      showSnackbarAlert(MESSAGES.PREPARE_SUCCESS);
      trackEnd(OPERATIONAL_PROCESS.PREPARE, ACTIVITY.FULL_PROCESS);
      trackStart(OPERATIONAL_PROCESS.PREPARE, ACTIVITY.FULL_PROCESS);
    }

    finish(pkgsWithoutLabel);
  };

  const handleClosePrepareInfoWithoutLabelDialog = () => {
    setPkgsToPrepareInfoWithoutLabel([]);
    trackEnd(OPERATIONAL_PROCESS.PREPARE, ACTIVITY.FULL_PROCESS);
    trackStart(OPERATIONAL_PROCESS.PREPARE, ACTIVITY.FULL_PROCESS);
  };

  useEffect(() => {
    if (
      history.action !== HISTORY_ACTIONS.PUSH ||
      !location.state?.mainLabelType ||
      !location.state?.distributionCenterId
    ) {
      history.replace(ROUTES.PREPARE.CHOOSE_LABEL_TYPE);
    }
  }, [history, location.state]);

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.PREPARE, ACTIVITY.FULL_PROCESS);
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    trackStart(OPERATIONAL_PROCESS.PREPARE, ACTIVITY.BEEP_PACKAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box data-testid="prepare">
      <HeaderWithReturn title="Preparar pacotes" onReturn={handleGoBack} />
      <Container maxWidth="xs">
        <Box
          pt={8}
          display="flex"
          flexDirection="column"
          height={1}
          overflow="hidden"
        >
          <Box display="flex" flexDirection="column" height={1}>
            <PrepareInfoReader
              onSuccessCallback={handlePrepareInfo}
              onErrorCallback={handleError}
              mainLabelType={mainLabelType}
              distributionCenterId={distributionCenterId}
              postingCard={postingCard}
              printReason={printReason}
            />
          </Box>
        </Box>
      </Container>
      <PrintingPackages
        pkgsToPrepareInfo={pkgsToPrepareInfo}
        onRemovePackages={handleRemovePackages}
        onConfirm={handleConfirmPrint}
      />
      {pkgsToPrepareInfoWithoutLabel.length > 0 && (
        <PackagesWithoutLabelDialog
          open
          pkgsToPrepareInfoWithoutLabel={pkgsToPrepareInfoWithoutLabel}
          onCancel={handleClosePrepareInfoWithoutLabelDialog}
        />
      )}
      {openConfirmGoBack && (
        <Confirmation
          open
          image={
            <Avatar
              src={AlertIcon}
              style={{ height: pxToRem(45), width: pxToRem(45) }}
            />
          }
          titleText="Tem certeza que quer cancelar?"
          subtitleText="Se você cancelar vai perder a lista de pacotes bipados."
          onConfirm={goBack}
          confirmText="Quero cancelar"
          onCancel={handleCancelGoBack}
          cancelText="Ficar na lista"
        />
      )}
    </Box>
  );
}
