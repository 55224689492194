import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  Avatar,
  Box,
  Button,
  Container,
  GridList,
  Typography
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { ReceivingProcessContext } from '../receiving-process-context';
// eslint-disable-next-line import/no-cycle
import { ReceivedPackagesList, useStyles } from '../receive';
import HeaderWithCancel from '../../../app/components/header-with-cancel';
// eslint-disable-next-line import/no-cycle
import { BUTTONS } from '../counter/receive-counter';

moment.updateLocale('pt-BR');

export default function ReceivePartner({
  children,
  receivedPackages,
  goBack,
  completeReceive
}) {
  return (
    <Box height="100vh" bgcolor={colors.root[0]} overflow="hidden">
      <Container maxWidth="xs">
        <PartnerHeader goBack={goBack} receivedPackages={receivedPackages} />
        {children}
        <PartnerPackages completeReceive={completeReceive} />
      </Container>
    </Box>
  );
}

ReceivePartner.propTypes = {
  children: PropTypes.element.isRequired,
  receivedPackages: PropTypes.arrayOf(
    PropTypes.shape({
      package: PropTypes.string
    })
  ).isRequired,
  goBack: PropTypes.func.isRequired,
  completeReceive: PropTypes.func.isRequired
};

export function PartnerPackages({ completeReceive }) {
  return (
    <Box width="100%" mt={1}>
      <Box
        display="flex"
        flexDirection="column"
        style={{
          position: 'relative',
          overflowY: 'auto',
          maxHeight: `calc(55vh - 100px)`
        }}
        flex="1 1 1"
      >
        <ReceivedPackagesList />
      </Box>
      <Container
        maxWidth="xs"
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0
        }}
      >
        <Box flex="none" mb={3}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            size="large"
            onClick={() => completeReceive()}
            data-testid="button-confirm-receive"
          >
            {BUTTONS.CONFIRM}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

PartnerPackages.propTypes = {
  completeReceive: PropTypes.func.isRequired
};

export function PartnerHeader({ goBack, receivedPackages }) {
  const classes = useStyles();
  const { senderPackages } = useContext(ReceivingProcessContext);
  return (
    <Box className={classes.flexBox} overflow="hidden">
      <Box>
        <Box>
          <Box className={classes.grid}>
            <HeaderWithCancel
              testId="button-cancel-receive"
              handleClose={goBack}
              isDisabled={false}
            />
          </Box>
          <Box paddingTop={1.5}>
            <Typography component="div" variant="body1" gutterBottom>
              <Box fontWeight="fontWeightBold">Receber</Box>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box overflow="hidden" flex={1}>
        <Box className={classes.flexBox}>
          <Box>
            <Box>
              <Typography variant="h3" display="inline">
                Coleta{' '}
              </Typography>
              <Typography
                variant="h3"
                style={{ color: colors.blue[500] }}
                display="inline"
                data-testid="receive-package-count"
              >
                {receivedPackages.length}{' '}
              </Typography>
              <Typography
                variant="h3"
                display="inline"
                data-testid="receive-package-total"
              >
                de {senderPackages.length}
              </Typography>
            </Box>
            <DriverPartnerInfo />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

PartnerHeader.propTypes = {
  receivedPackages: PropTypes.arrayOf(
    PropTypes.shape({
      package: PropTypes.string
    })
  ).isRequired,
  goBack: PropTypes.func.isRequired
};

export function DriverPartnerInfo() {
  const classes = useStyles();
  const { sender } = useContext(ReceivingProcessContext);
  return (
    <GridList cellHeight="auto" cols={5} spacing={1}>
      <Box cols={3.5} className={classes.flexName}>
        <Typography variant="body1">
          <Box fontWeight="fontWeightBold" data-testid="sender-name">
            {sender.name}
          </Box>
        </Typography>
        {sender.cpf && (
          <Typography variant="caption" data-testid="sender-cpf">
            CPF: {sender.cpf}
          </Typography>
        )}
      </Box>
      <Box cols={1.5} className={classes.flexAvatar}>
        {sender.photoUrl && (
          <Avatar
            src={sender.photoUrl}
            className={classes.smallAvatar}
            data-testid="sender-avatar"
          />
        )}
      </Box>
    </GridList>
  );
}
