import { colors } from '@loggi/mar';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import FlipIcon from '@material-ui/icons/Flip';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import LabelIcon from '@material-ui/icons/Label';
import PrintIcon from '@material-ui/icons/Print';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import { Alert, AlertTitle } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import FilterDramaRounded from '@material-ui/icons/FilterDramaRounded';
import ControlPointDuplicateRounded from '@material-ui/icons/ControlPointDuplicateRounded';
import { useDistributionCenter } from './access-control/distribution-center-provider';

import Logo from '../assets/images/LoggiXD.png';
import { COGNITO_DISTRIBUTION_CENTER, ROUTES, SWITCHES } from '../constants';
import { Can } from './access-control/access-control-container';
import { ACTIONS } from './access-control/access-control-rules';
import getUserType from './access-control/access-control-service';
import { DistributionCenterSelectButton } from './components/distribution-center-select';
import UpdateAvailableDialog from './components/update-available-dialog';
import UserInfoButton from './components/user-info-button.component';
import { useFeature } from './hooks/use-feature';

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },

  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    backgroundColor: colors.smoke[50]
  },
  menuButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  headerText: {
    marginLeft: theme.spacing(1)
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    width: '100%'
  },
  appVersion: {
    margin: theme.spacing(1),
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    textAlign: 'center',
    align: 'center'
  },
  navigationMenu: {
    display: 'flex',
    padding: 0
  }
}));

export function AuthenticatedMenu() {
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const isUserLeve =
    getUserType(selectedDistributionCenter) ===
    COGNITO_DISTRIBUTION_CENTER.LEVE_USER;

  const enableLevePacking = useFeature(SWITCHES.enableLevePacking);
  const enableLeveTransport = useFeature(SWITCHES.enableLeveTransport);
  const enableLeveIdentify= useFeature(SWITCHES.enableLeveIdentify);

  const disableTransportTransferMenu = useFeature(
    SWITCHES.disableTransportTransferMenu
  );

  const disableConferenciaMeliMenu = useFeature(
    SWITCHES.disableConferenciaMeliMenu
  );

  const canShowPrinter = useFeature(SWITCHES.enablePrinterMenu);
  const canShowPacking = !isUserLeve || enableLevePacking;
  const canShowIdentify = !isUserLeve || enableLeveIdentify;

  const canShowTransport =
    enableLeveTransport ||
    (!isUserLeve &&
      (!disableConferenciaMeliMenu || !disableTransportTransferMenu));

  return (
    <List>
      <MenuItem component={Link} to="/">
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Início" />
      </MenuItem>
      <Can actions={[ACTIONS.SORT]}>
        <MenuItem component={Link} to="/sorting">
          <ListItemIcon>
            <ShuffleIcon />
          </ListItemIcon>
          <ListItemText primary="Separar" />
        </MenuItem>
      </Can>
      {canShowPacking && (
        <Can actions={[ACTIONS.PACKING]}>
          <MenuItem
            component={Link}
            to="/packing"
            data-testid="packing-list-menu"
          >
            <ListItemIcon>
              <ControlPointDuplicateRounded />
            </ListItemIcon>
            <ListItemText primary="Empacotar" />
          </MenuItem>
        </Can>
      )}
      {canShowIdentify && (
        <Can actions={[ACTIONS.IDENTIFY]}>
          <MenuItem component={Link} to="/identify">
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary="Identificar" />
          </MenuItem>
        </Can>
      )}
      <Can actions={[ACTIONS.DISTRIBUTE_TRANSFERS]}>
        <MenuItem
          component={Link}
          to={ROUTES.DISTRIBUTE.IDENTIFICATION}
          data-testid="distribute-list-menu"
        >
          <ListItemIcon>
            <ArrowRightAltRoundedIcon style={{ transform: `rotate(270deg)` }} />
          </ListItemIcon>
          <ListItemText primary="Distribuir" />
        </MenuItem>
      </Can>
      <Can actions={[ACTIONS.DISPATCH]}>
        <MenuItem component={Link} to="/dispatch">
          <ListItemIcon>
            <FilterDramaRounded />
          </ListItemIcon>
          <ListItemText primary="Expedir" />
        </MenuItem>
      </Can>
      <Can actions={[ACTIONS.RECEIVE]}>
        <MenuItem component={Link} to="/receive/context-reader">
          <ListItemIcon>
            <ArrowRightAltRoundedIcon style={{ transform: `rotate(90deg)` }} />
          </ListItemIcon>
          <ListItemText primary="Receber" />
        </MenuItem>
      </Can>
      <Can actions={[ACTIONS.CHANGE_LABEL]}>
        <MenuItem component={Link} to="/change-label">
          <ListItemIcon>
            <LabelIcon />
          </ListItemIcon>
          <ListItemText primary="Trocar etiqueta" />
        </MenuItem>
      </Can>
      {canShowPrinter && (
        <Can actions={[ACTIONS.PRINTER]}>
          <MenuItem component={Link} to="/printer" data-testid="printer-menu">
            <ListItemIcon>
              <PrintIcon />
            </ListItemIcon>
            <ListItemText primary="Imprimir" />
          </MenuItem>
        </Can>
      )}
      {canShowTransport && (
        <Can actions={[ACTIONS.TRANSPORT]}>
          <MenuItem
            component={Link}
            to="/transport"
            data-testid="transport-list-menu"
          >
            <ListItemIcon>
              <LocalShippingIcon />
            </ListItemIcon>
            <ListItemText primary="Transportes" />
          </MenuItem>
        </Can>
      )}
      <Can actions={[ACTIONS.PREPARE]}>
        <MenuItem component={Link} to="/prepare/choose-label-type">
          <ListItemIcon>
            <FlipIcon transform="rotate(180)" />
          </ListItemIcon>
          <ListItemText primary="Preparar pacotes" />
        </MenuItem>
      </Can>

      <Can actions={[ACTIONS.ORGANIZE]}>
        <MenuItem component={Link} to={ROUTES.ORGANIZE.INITIAL_IDENTIFICATION}>
          <ListItemIcon>
            <ArrowRightAltRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Organizar" />
        </MenuItem>
      </Can>

      <MenuItem component={Link} to="/logout">
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </MenuItem>
    </List>
  );
}

export function CardButton({ children }) {
  return (
    <Card>
      <Box
        color="text.secondary"
        padding={2}
        alignItems="center"
        display="flex"
        flexDirection="column"
        width="84px"
      >
        {children}
      </Box>
    </Card>
  );
}
CardButton.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

export function Home() {
  const alertText =
    'Ao realizar qualquer operação você será registrado como a última pessoa a ter visto o pacote. Apenas prossiga com o pacote em mãos.';

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const isUserLeve =
    getUserType(selectedDistributionCenter) ===
    COGNITO_DISTRIBUTION_CENTER.LEVE_USER;

  const enableLevePacking = useFeature(SWITCHES.enableLevePacking);
  const enableLeveTransport = useFeature(SWITCHES.enableLeveTransport);

  const disableTransportTransferMenu = useFeature(
    SWITCHES.disableTransportTransferMenu
  );

  const disableConferenciaMeliMenu = useFeature(
    SWITCHES.disableConferenciaMeliMenu
  );

  const canShowPacking = !isUserLeve || enableLevePacking;
  const canShowTransport =
    enableLeveTransport ||
    (!isUserLeve &&
      (!disableConferenciaMeliMenu || !disableTransportTransferMenu));
  const canShowPrinter = useFeature(SWITCHES.enablePrinterMenu);

  return (
    <Container data-testid="home-screen">
      <br />
      <Alert severity="warning" color="warning">
        <AlertTitle>Atenção</AlertTitle>
        <Box> {alertText} </Box>
      </Alert>
      <br />
      <Typography variant="h3" style={{ fontWeight: 'bold' }}>
        Vamos lá!
      </Typography>
      <Typography variant="body2" style={{ fontWeight: 600 }} gutterBottom>
        Selecione uma operação:
      </Typography>
      <br />
      <Grid spacing={2} container direction="row" justify="flex-start">
        <Can actions={[ACTIONS.SORT]}>
          <Grid
            component={Link}
            to="/sorting"
            item
            style={{ textDecoration: 'none', outline: 'none' }}
          >
            <CardButton>
              <ShuffleIcon />
              <Typography variant="caption">Separar</Typography>
            </CardButton>
          </Grid>
        </Can>
        {canShowPacking && (
          <Can actions={[ACTIONS.PACKING]}>
            <Grid
              item
              component={Link}
              to="/packing"
              style={{ textDecoration: 'none', outline: 'none' }}
              data-testid="packing-icon"
            >
              <CardButton>
                <ControlPointDuplicateRounded />
                <Typography variant="caption">Empacotar</Typography>
              </CardButton>
            </Grid>
          </Can>
        )}
        <Can actions={[ACTIONS.IDENTIFY]}>
          <Grid
            item
            component={Link}
            to="/identify"
            style={{ textDecoration: 'none', outline: 'none' }}
          >
            <CardButton>
              <SearchIcon />
              <Typography variant="caption">Identificar</Typography>
            </CardButton>
          </Grid>
        </Can>
        <Can actions={[ACTIONS.DISTRIBUTE_TRANSFERS]}>
          <Grid
            item
            component={Link}
            to={ROUTES.DISTRIBUTE.IDENTIFICATION}
            style={{ textDecoration: 'none', outline: 'none' }}
            data-testid="distribute-icon"
          >
            <CardButton>
              <ArrowRightAltRoundedIcon
                style={{ transform: `rotate(270deg)` }}
              />
              <Typography variant="caption">Distribuir</Typography>
            </CardButton>
          </Grid>
        </Can>
        <Can actions={[ACTIONS.DISPATCH]}>
          <Grid
            item
            component={Link}
            to="/dispatch"
            style={{ textDecoration: 'none', outline: 'none' }}
          >
            <CardButton>
              <FilterDramaRounded />
              <Typography variant="caption">Expedir</Typography>
            </CardButton>
          </Grid>
        </Can>
        <Can actions={[ACTIONS.RECEIVE]}>
          <Grid
            item
            component={Link}
            to="/receive/context-reader"
            style={{ textDecoration: 'none', outline: 'none' }}
          >
            <CardButton>
              <ArrowRightAltRoundedIcon
                style={{ transform: `rotate(90deg)` }}
              />
              <Typography variant="caption">Receber</Typography>
            </CardButton>
          </Grid>
        </Can>
        {canShowTransport && (
          <Can actions={[ACTIONS.TRANSPORT]}>
            <Grid
              item
              component={Link}
              to="/transport"
              style={{ textDecoration: 'none', outline: 'none' }}
              data-testid="transport-icon"
            >
              <CardButton>
                <LocalShippingIcon />
                <Typography variant="caption">Transportes</Typography>
              </CardButton>
            </Grid>
          </Can>
        )}
        <Can actions={[ACTIONS.PREPARE]}>
          <Grid
            item
            component={Link}
            to="/prepare/choose-label-type"
            style={{ textDecoration: 'none', outline: 'none' }}
            data-testid="prepare-icon"
          >
            <CardButton>
              <FlipIcon transform="rotate(180)" />
              <Typography variant="caption">Preparar pacotes</Typography>
            </CardButton>
          </Grid>
        </Can>
        <Can actions={[ACTIONS.ORGANIZE]}>
          <Grid
            item
            component={Link}
            to={ROUTES.ORGANIZE.INITIAL_IDENTIFICATION}
            style={{ textDecoration: 'none', outline: 'none' }}
            data-testid="organize-icon"
          >
            <CardButton>
              <ArrowRightAltRoundedIcon />
              <Typography variant="caption">Organizar</Typography>
            </CardButton>
          </Grid>
        </Can>
        {canShowPrinter && (
          <Can actions={[ACTIONS.PRINTER]}>
            <Grid
              item
              component={Link}
              to="/printer"
              style={{ textDecoration: 'none', outline: 'none' }}
              data-testid="printer-icon"
            >
              <CardButton>
                <PrintIcon />
                <Typography variant="caption">Imprimir</Typography>
              </CardButton>
            </Grid>
          </Can>
        )}
      </Grid>
    </Container>
  );
}

export function MenuWrapper({ children }) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClose = () => {
    setMobileOpen(false);
  };

  const menuBar = (
    <div>
      <div className={classes.toolbar}>
        <img src={Logo} alt="Loggi" height="60" />
      </div>
      <Divider />
      <List>
        <AuthenticatedMenu />
      </List>
      <Divider />
      <Box className={classes.appVersion}>
        <Typography noWrap>{process.env.REACT_APP_VERSION}</Typography>
      </Box>
    </div>
  );

  return (
    <div className={classes.root} data-testid="menu-wrapper">
      <UpdateAvailableDialog />
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" className={classes.headerText} noWrap />
          <Box display="flex" flexGrow={1} />
          <Box mr={2}>
            <DistributionCenterSelectButton />
          </Box>
          <UserInfoButton />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onClick={handleClose}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {menuBar}
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {menuBar}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>{children}</main>
    </div>
  );
}

MenuWrapper.propTypes = {
  children: PropTypes.element.isRequired
};

export function withNavigationMenu(Wrapped) {
  return props => {
    return (
      <MenuWrapper>
        <Container style={{ padding: 0 }}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Wrapped {...props} />
        </Container>
      </MenuWrapper>
    );
  };
}
