import {
  Avatar,
  Box,
  Container,
  Typography,
  List,
  ListItemText
} from '@material-ui/core';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { pxToRem } from '@loggi/mar/src/utils';
import { colors } from '@loggi/mar';
import SelectableButton from '../../app/components/selectable-button';
import HeaderWithReturn from '../../app/components/header-with-return';
import CheckboxListItem from '../../app/components/checkbox-list-item';
import { LABEL_TYPE } from '../../app/enums';
import { getPostingCards } from '../../api-rest';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import AsyncButton from '../../app/components/async-button';
import LogoCorreios from '../../assets/images/correios-logo.svg';
import RedispatchAgencyDialog from './redispatch-agency-dialog';
import Confirmation from '../../app/components/confirmation';
import { ReactComponent as NotFoundImage } from '../../assets/images/endpoint-error.svg';
import { ReactComponent as NetworkErrorImage } from '../../assets/images/no-wifi.svg';
import handleRestAPIError from '../../app/utils/rest-api-request';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { useFeature } from '../../app/hooks/use-feature';
import { NETWORK_ERROR_MESSAGE, SWITCHES } from '../../constants';

const MAIN_LABEL_TYPES = {
  [LABEL_TYPE.LABEL_TYPE_LOGGI]: 'Loggi',
  [LABEL_TYPE.LABEL_TYPE_REDISPATCH]: 'Correios'
};

const PRINT_REASONS = {
  REASON_UNSPECIFIED: 0,
  SHIPPER_ISSUE: 1
};

const CorreiosAvatar = () => {
  return (
    <Avatar
      src={LogoCorreios}
      style={{
        width: pxToRem(45),
        height: pxToRem(45),
        padding: pxToRem(6),
        border: `${pxToRem(1)} solid ${colors.smoke[500]}`,
        backgroundColor: colors.smoke[50]
      }}
    />
  );
};

export default function ChooseLabelType() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedMainLabelType, setSelectedMainLabelType] = useState('');
  const [postingCards, setPostingCards] = useState([]);
  const [dialogAlertProps, setDialogAlertProps] = useState(null);
  const [selectedDecon, setSelectedDecon] = useState(false);
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const { distributionCenterId } = selectedDistributionCenter;
  const enablePrintDecon = useFeature(SWITCHES.enablePrintDecon);
  const [isShippingIssue, setIsShippingIssue] = useState(false);
  const [printReason, setPrintReason] = useState(
    PRINT_REASONS.REASON_UNSPECIFIED
  );
  const enablePrintIssues = useFeature(SWITCHES.enablePrintIssues);

  const showSnackbarAlert = (message, variant) => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const goBack = () => history.push({ pathname: '/' });

  const goForward = (mainLabelType, postingCard) => {
    history.push({
      pathname: '/prepare',
      state: { mainLabelType, postingCard, distributionCenterId, printReason }
    });
  };

  const handleLabelClick = labelType => {
    if (labelType !== selectedMainLabelType) {
      setSelectedMainLabelType(labelType);
      setSelectedDecon(false);
      setIsShippingIssue(false);
      return;
    }
    setSelectedMainLabelType('');
  };

  const handleSelectDecon = () => {
    setSelectedDecon(!selectedDecon);
  };

  const handlePrintShipperIssue = () => {
    setIsShippingIssue(!isShippingIssue);
    setPrintReason(PRINT_REASONS.SHIPPER_ISSUE);
  };

  const handleLoggiLabel = () => goForward(LABEL_TYPE.LABEL_TYPE_LOGGI, null);

  const closeDialogAlert = () => setDialogAlertProps(null);

  const retryGetPostingCards = async () => {
    closeDialogAlert();
    // eslint-disable-next-line no-use-before-define
    await handleRedispatchLabel();
  };

  const networkErrorDialogProps = {
    image: <NetworkErrorImage />,
    titleText: 'Eita! Algo deu errado.',
    onConfirm: retryGetPostingCards,
    confirmText: 'Tentar de novo',
    onCancel: closeDialogAlert,
    cancelText: 'Entendi'
  };

  const postingCardNotFoundDialogProps = {
    image: <NotFoundImage />,
    titleText: 'Opa! Ainda não tem nenhuma AGF vinculada à sua base.',
    subtitleText: 'Fale com o suporte para cadastrar uma parceira.',
    onConfirm: closeDialogAlert,
    confirmText: 'Entendi'
  };

  const handleError = err => {
    if (err?.response?.status === 404) {
      setDialogAlertProps(postingCardNotFoundDialogProps);
      return;
    }

    handleRestAPIError(err, msg => {
      const isNetworkError = msg === NETWORK_ERROR_MESSAGE;
      if (isNetworkError) {
        const props = { subtitleText: msg, ...networkErrorDialogProps };
        setDialogAlertProps(props);
      } else {
        showSnackbarAlert(msg, 'error');
      }
    });
  };

  const handleRedispatchLabel = async () => {
    const response = await getPostingCards(distributionCenterId).catch(err => {
      handleError(err);
    });

    if (!response) return;

    // Don't render selection and go forward if has only one posting card.
    if (response.data.postingCards.length === 1) {
      goForward(
        LABEL_TYPE.LABEL_TYPE_REDISPATCH,
        response.data.postingCards[0]
      );
    }

    setPostingCards(response.data.postingCards);
  };

  const handlePrintDecon = () => {
    goForward(LABEL_TYPE.LABEL_TYPE_DECON, null);
  };

  const handleButtonClick = async () => {
    if (selectedMainLabelType === LABEL_TYPE.LABEL_TYPE_LOGGI) {
      handleLoggiLabel();
    } else if (selectedMainLabelType === LABEL_TYPE.LABEL_TYPE_REDISPATCH) {
      await handleRedispatchLabel();
    } else if (selectedDecon) {
      handlePrintDecon();
    }
  };

  const handleRedispatchAgencySelection = item => {
    const postingCard = postingCards.filter(
      value => value.number === item.key
    )[0];
    goForward(LABEL_TYPE.LABEL_TYPE_REDISPATCH, postingCard);
  };

  const getDataTestId = labelValue => {
    return labelValue.toLowerCase().concat('-', 'button');
  };

  const postingCardsToItemList = postingCardList => {
    return postingCardList.map(postingCard => {
      return {
        title: postingCard.name,
        subtitle: 'Correios',
        icon: <CorreiosAvatar />,
        key: postingCard.number
      };
    });
  };

  return (
    <Box>
      <HeaderWithReturn title="Preparar pacotes" onReturn={goBack} />
      <Container maxWidth="xs">
        <Box
          pt={10}
          display="flex"
          flexDirection="column"
          overflow="hidden"
          height="100vh"
        >
          <Box>
            <Box>
              <Typography variant="h6">Do que você precisa?</Typography>
            </Box>
            <Box pt={4}>
              <Typography variant="body1">Etiquetas</Typography>
            </Box>
          </Box>
          <Box pt={2.5} display="flex" flexDirection="row">
            {Object.keys(MAIN_LABEL_TYPES).map((labelKey, index) => {
              const labelValue = MAIN_LABEL_TYPES[labelKey];
              const isSelected = selectedMainLabelType === labelKey;
              return (
                <Box mr={index % 2 === 0 ? 2 : 0} key={labelKey}>
                  <SelectableButton
                    dataTestId={getDataTestId(labelValue)}
                    label={labelValue}
                    onClick={() => handleLabelClick(labelKey)}
                    isSelected={isSelected}
                  >
                    {labelValue}
                  </SelectableButton>
                </Box>
              );
            })}
          </Box>
          <Box pt={2.5}>
            {enablePrintDecon && (
              <>
                <Typography variant="body1">Documentos</Typography>
                <List>
                  <CheckboxListItem
                    key="decon"
                    testIdListItem="decon-list-item"
                    onClick={handleSelectDecon}
                    selected={selectedDecon}
                  >
                    <ListItemText>Declaração de conteúdo</ListItemText>
                  </CheckboxListItem>
                </List>
              </>
            )}
          </Box>
          <Box pt={2.5} flex={1}>
            {enablePrintIssues &&
              selectedMainLabelType === LABEL_TYPE.LABEL_TYPE_LOGGI && (
                <>
                  <Typography variant="body1">
                    Problema com a etiqueta do embarcador
                  </Typography>
                  <List>
                    <CheckboxListItem
                      key="label-issue"
                      testIdListItem="shipper-issue-list-item"
                      onClick={handlePrintShipperIssue}
                      selected={isShippingIssue}
                    >
                      <ListItemText>
                        Reimpressão por erro do embarcador
                      </ListItemText>
                    </CheckboxListItem>
                  </List>
                </>
              )}
          </Box>
          <Box mb={2}>
            <AsyncButton
              fullWidth
              data-testid="start-button"
              color="primary"
              variant="contained"
              size="large"
              isDisabled={!(selectedDecon || selectedMainLabelType)}
              onClick={handleButtonClick}
            >
              Começar
            </AsyncButton>
          </Box>
        </Box>
      </Container>
      {postingCards.length > 0 && (
        <RedispatchAgencyDialog
          title="Selecionar Parceira"
          itemList={postingCardsToItemList(postingCards)}
          onSelect={handleRedispatchAgencySelection}
          data-testid="redispatch-agency-drawer"
        />
      )}
      {dialogAlertProps && (
        <Confirmation
          open
          image={dialogAlertProps.image}
          titleText={dialogAlertProps.titleText}
          subtitleText={dialogAlertProps.subtitleText}
          onConfirm={dialogAlertProps.onConfirm}
          confirmText={dialogAlertProps.confirmText}
          onCancel={dialogAlertProps.onCancel}
          cancelText={dialogAlertProps.cancelText}
        />
      )}
    </Box>
  );
}
